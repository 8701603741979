import Banner from "./components/Banner"
import Header from "./components/Header"
import "./assets/styles/globals.css"
import {Footer} from "./components/Footer"
import Contacts from "./components/Contacts"
import Modal from "./components/Modal"
import {useState} from "react"

function App() {
	const [modalHidden, setModalHidden] = useState(true)

	return (
		<div className='App' style={{height: "100vh", maxWidth: "100vw", overflowX: "hidden", overflowY: modalHidden ? "scroll" : "hidden"}}>
			<Modal modalHidden={modalHidden} setModalHidden={setModalHidden} />
			<Header />
			<Banner setModalHidden={setModalHidden} />
			<Contacts />
			<Footer />
		</div>
	)
}

export default App
