import Container from "../Container"
import styles from "./styles.module.css"

export function Footer() {
	return (
		<footer className={styles.footer}>
			<Container>
				<div className={styles.logo}>
					<img src='/logo.svg' />
				</div>
				<div className={styles.title}>© 2023 Автохолдинг «Максимум»</div>
				<a className={styles.policy} href='https://maximum-auto.ru/privacy_policy/' target='_blank'>
					Политика обработки персональных данных
				</a>
				<p>
					Данный сайт носит информационный характер и ни при каких условиях материалы и цены размещенные на сайте не являются публичной офертой.
				</p>
			</Container>
		</footer>
	)
}
