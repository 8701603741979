import Container from "../Container"
import styles from "./styles.module.css"

export default function Header() {
	return (
		<Container noPaddingBottom noPaddingTop>
			<header className={styles.header}>
				<div className={styles.logo}>
					<img src='/logoBlack.svg' />
				</div>

				<div className={styles.location}>
					<div>Транспортная территория, 6, Санкт-Петербург</div>
					<div>Время работы: с 9:00 до 22:00</div>
				</div>
				<div>
					<a href='tel:+78126035585'>+7 (812) 603-55-85</a>
				</div>
			</header>
		</Container>
	)
}
