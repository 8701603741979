import styles from "./styles.module.css"

export default function Contacts() {
	return (
		<div className={styles.contacts}>
			<div className={styles.map}>
				<iframe
					src="https://yandex.ru/map-widget/v1/?um=constructor%3A76038a4eeef1b0719e2cb2fe07c8a23b066aaebca0626cc8a48ef59f177e1bfc&amp;source=constructor"
					width="845" height="469" frameBorder="0">

				</iframe>
			</div>

			<div className={styles.info}>
				<div className={styles.title}>Максимум авто</div>
				<div className={styles.description}>Автомобили с пробегом в Санкт-Петербурге</div>
				<a href='tel:+78126035585' className={styles.phone}>
					+7 (812) 603-55-85
				</a>
				<div className={styles.time}>Время работы: с 9:00 до 22:00</div>
			</div>
		</div>
	)
}
