import styles from "./styles.module.css"
import banner from "./banner1.jpg"
export default function Banner({setModalHidden}) {
	return (
		<section className={styles.banner}>
			<div className={styles.image}>
				<img src={banner} />
			</div>
			<div className={styles.content}>
				<div className={styles.title}>Приглашаем Вас на день открытых дверей в <strong>Максимум</strong>!</div>
				<br/>
				<div className={styles.subtitle}>25 ноября, суббота, начало в 12:00</div>
				<div className={styles.subtitle}>ДЦ Максимум Транспортная</div>
				<div className={styles.footer}>Программа:</div>
				<ul>
					<li>Вкуснейший фуршет, шампанское, кофе-бар</li>
					<li>Развлекательная программа с призами от банков-партнеров</li>
					<li>Мастер-классы для взрослых и детей, детская анимация и конкурсы</li>
					<li>Памятные подарки и сертификаты на сервис для гостей</li>
					<li>Персональные скидки на автомобили только в этот день!</li>
				</ul>
				<button className={styles.button} onClick={() => setModalHidden(false)}>
					Получить приглашение
				</button>
			</div>
		</section>
	)
}
